import { mediaQuery } from 'helpers/hooks/useResponsive';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 1rem;
  p.para {
    font-size: ${22 / 16}rem;
    line-height: 1.5;
  }
  .address-wrap {
    max-height: 472px;
    overflow-y: auto;
  }
  .checkoutFormContainer {
    background: #ffffff;
    padding: 1rem 1rem 2rem 1rem;
    border: 1px solid #ffffff;
    border-radius: 0.75rem;
    box-shadow: 0px 0.25rem 2rem rgba(0, 0, 0, 0.06);
  }
  .shippingFormContainer {
    background: #ffffff;
    padding: 1.75rem;
    border: 1px solid #ffffff;
    border-radius: 0.75rem;
    box-shadow: 0px 0.25rem 2rem rgba(0, 0, 0, 0.06);
    .shipping-method-card {
      cursor: pointer;
      input[type='checkbox'] {
        cursor: pointer;
      }
    }
  }
  select {
    width: 100%;
    height: 3.5rem;
    padding: 0.625rem;
    border-color: #e7e7e7;
    box-shadow: 0px 0.25rem 2.5rem rgba(0, 0, 0, 0.04);
    border-radius: 0.5rem;
  }
  .checkout-main {
    margin: 3rem 0px 0px 3rem;
    @media (max-width: 768px) {
      margin: 3rem 0px 0px 0rem;
    }
    .checkout-wrapper {
      width: 100%;
      .select-bullet-main {
        flex-direction: column;
        .select-line {
          height: 39.45rem;
        }
        .select-line3 {
          height: 2.75rem;
        }
        .select-line2 {
          height: 6.25rem;
        }
      }
      .checkout-section-wrapper {
        width: 100%;
        .form-main-section {
          margin: 0px 0px 3.125rem 1.25rem;
          @media (max-width: 768px) {
            margin: 0px 0px 3.125rem 0rem;
          }
          .form-title {
            margin: 0.625rem 0px;
          }
        }

        .content-wrap {
          background: #ffffff;
          padding: 1rem 1rem 1rem 1rem;
          border: 1px solid #ffffff;
          border-radius: 0.75rem;
          box-shadow: 0px 0.25rem 2rem rgba(0, 0, 0, 0.06);
        }

        .form-label {
          color: #292d32;
          margin-top: 1rem;
        }

        .address-form-input {
          border: 1px solid #e7e7e7;
          box-shadow: 0px 0.25rem 2.5rem rgba(0, 0, 0, 0.04);
          border-radius: 0.5rem;
          padding: 1rem;
          width: 100%;
        }
        .rbt-aux {
          position: absolute;
          top: 1.125rem;
          right: 0.75rem;
        }
      }
    }
  }
`;

export const Content = styled(Container)`
  max-width: 1280px;
  min-height: 60vh;
`;

export const OrderDetailsWrapper = styled(Container)`
  box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.06);
  padding: 1.5rem;
  min-width: 15.625rem;
  margin: 3.375rem 0px 0px 1rem;
  @media (max-width: 768px) {
    margin: 0;
  }
  .summary-row-devider {
    opacity: 0.1;
    border: 1px solid #000000;
    margin: 1rem 0px 1rem;
  }
`;

export const PaymentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  .paymentPriceWrapper {
    border: solid 1px #dddddd;
    width: 550px;
    padding: 1rem 1.5rem;
    border-radius: 1rem;
    gap: 2rem;
    h3 {
      font-size: 2rem;
      font-weight: 700;
    }
  }
  @media ${mediaQuery.mobile} {
    flex-direction: column;
    align-items: center;
    div {
      width: 100%;
      text-align: center;
    }
    .paymentPriceWrapper {
      border: solid 1px #dddddd;
      width: 400px;
      max-width: 100%;
    }
  }
`;

export const PaymentTabButton = styled.div`
  border: 1px solid #d4d4d4;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  padding: 0 20px;
  height: 42px;
  font-size: 16px;
  cursor: pointer;
  :hover {
    border-color: #000000;
  }
  &.active {
    border: 2px solid #000000;
  }
  @media ${mediaQuery.mobile} {
    justify-content: center;
  }
`;

export const CreditCardPaymentWrapper = styled.div`
  margin-top: 1.5rem;
  padding: 20px 20px;
  border: 1px solid #dddddd;
  border-radius: 10px;
`;
